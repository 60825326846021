import React, { useState, useEffect } from "react";
import project1 from "../../Assets/png/project-img1.jpg";
import project2 from "../../Assets/png/project-img2.jpg";
import project3 from "../../Assets/png/project-img3.jpg";
import project4 from "../../Assets/png/project-img4.jpg";
import cendrolprojectsqft from "../../Assets/Svg/cendrolprojectsqft.svg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import bathroom from "../../Assets/Svg/bathroom.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { getValue } from "@testing-library/user-event/dist/utils";
import LazyLoad from "react-lazy-load";

export default function Cards() {
  useEffect(() => {
    getCategories();
  }, []);

  const SERVER_URL = process.env.REACT_APP_BASE_URL + localStorage.getItem("e");
  const IMG_URL = process.env.REACT_APP_IMG_URL;
  const [projectsInCategory, setProjectsInCategory] = useState([]);
  const [categories, setCategories] = useState([]);
  // get all categories
  function getCategories() {
    // console.log("e", localStorage.getItem("e"));
    if (localStorage.getItem("e") == null) {
      localStorage.setItem("e", "customer");
    }
    axios
      .get(
        `${
          localStorage.getItem("e") === "customer"
            ? process.env.REACT_APP_BASE_URL
            : process.env.REACT_APP_BASE_URL_LOCAL_SERVER
        }${
          localStorage.getItem("e") === "customer"
            ? ""
            : localStorage.getItem("e")
        }/getcategories`
      )
      .then((res) => categoriesList(res));

    function categoriesList(res) {
      const resList = res.data.result;
      var catList = [];
      resList.map((cat) => catList.push(cat.Name));
      setCategories(catList);
      setActiveTab(catList[0]);
      getProjectsByCategory(catList[0]);
    }
  }

  function getProjectsByCategory(category) {
    axios
      .get(
        `${
          localStorage.getItem("e") === "customer"
            ? process.env.REACT_APP_BASE_URL
            : process.env.REACT_APP_BASE_URL_LOCAL_SERVER
        }${
          localStorage.getItem("e") === "customer"
            ? ""
            : localStorage.getItem("e")
        }/cencategoryprojects?category=${category}`
      )
      .then((response) => {
        // console.log("RPSOS", response);
        setProjectsInCategory(response.data.result);
        // setcategor(category);
      });
  }

  function checkActive(tabName) {
    if (activeTab === tabName) {
      return "activeTab";
    } else {
      return "tabs";
    }
  }
  const [activeTab, setActiveTab] = useState("All Projects");
  return (
    <div>
      <div className="main_div_cards">
        <div className="text-center w-100">
          <h2 className="project_list_title">Cendrol Projects</h2>
          <div className="mt-4">
            <div className="d-flex justify-content-center">
              <div className="TabSec ">
                {categories.map((category) => {
                  var bgColor = "#f5f5f5";
                  activeTab !== category
                    ? (bgColor = "blue")
                    : (bgColor = `red`);
                  return (
                    <p
                      style={{ background: "transparent" }}
                      className={checkActive(category)}
                      onClick={
                        () => {
                          setActiveTab(category);
                          getProjectsByCategory(category);
                        }
                        // setEleStatus([])
                      }
                    >
                      {category}
                    </p>
                  );
                })}
              </div>
            </div>
            {/* <div className="d-flex justify-content-center">
              <div className="TabSec">
                <p
                  onClick={() => setActiveTab("All Projects")}
                  className={checkActive("All Projects")}
                >
                  {"All Projects"}
                </p>
                <p
                  onClick={() => setActiveTab("Trending")}
                  className={checkActive("Trending")}
                >
                  {"Trending"}
                </p>

                <p
                  onClick={() => setActiveTab("Top 10")}
                  className={checkActive("Top 10")}
                >
                  {"Top 10"}
                </p>
                <p
                  onClick={() => setActiveTab("Completed")}
                  className={checkActive("Completed")}
                >
                  {"Completed"}
                </p>
                <p
                  onClick={() => setActiveTab("On Going")}
                  className={checkActive("On Going")}
                >
                  {"On Going"}
                </p>
              </div>
            </div> */}
            {/* {activeTab === "All Projects" && ( */}
            <div className="mt-4">
              <div className="rowHomeDesign">
                <div className="row">
                  {projectsInCategory?.map((result, index) => {
                    var rankVal = index + 1;
                    return (
                      <>
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                          <Link
                            to={`/details?${localStorage.getItem("e")}=${
                              result.project_name
                            }`}
                            className=""
                          >
                            <div className="contentHomeDesign">
                              <div className="imgDesign">
                                <div className="card_image">
                                  <LazyLoad>
                                    <img
                                      className="lazy"
                                      src={`${
                                        localStorage.getItem("e") === "customer"
                                          ? "https://d2aknbw0t7002k.cloudfront.net"
                                          : "https://d48j7fd3ucy92.cloudfront.net"
                                      }/${result?.project_images[0]}`}
                                      alt=""
                                      title=""
                                    />
                                  </LazyLoad>
                                  {/* <div className="project_tag_trending">
                                  {activeTab}
                                </div> */}
                                </div>
                              </div>
                              <div className="designDetail">
                                <div className="sizeDetail">
                                  <div className="name_area_sec">
                                    <p className="mb-2 project_name">
                                      {result?.project_name}
                                    </p>
                                    <div className="cendrolproject_location">
                                      <LocationOnIcon
                                        style={{
                                          color: "#FCC314",
                                          width: "17px",
                                        }}
                                      />
                                      <span className="cendrolproject_location_text ps-2">
                                        {result?.plot_location}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex bs_row">
                                    <div className=" cendrolproject_sqft  ">
                                      <img
                                        src={cendrolprojectsqft}
                                        alt=".."
                                        className="me-1"
                                      />
                                      <span className="cendrolproject__sqft_text ">
                                        {result?.builtup_area} Sq.ft.
                                      </span>
                                    </div>
                                    <div className=" cendrolproject_sqft">
                                      <img
                                        src={cendrolprojectsqft}
                                        alt=".."
                                        className="me-1"
                                      />
                                      <span className="cendrolproject__sqft_text ">
                                        {result?.specs}BHK
                                      </span>
                                    </div>
                                    <div className=" cendrolproject_sqft">
                                      <img
                                        src={bathroom}
                                        alt=".."
                                        className="me-1"
                                      />
                                      <span className="cendrolproject__sqft_text">
                                        {result?.no_of_bathrooms} Bath
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
