import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Listing from "./Components/Listing/Listing";
import ProjectDetails from "./Components/ProjectDetails/ProjectDetails";
import Test from "./Components/ProjectDetails/test";
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Listing />} />
          <Route path="/a" element={<Test />} />
          <Route path="/details" element={<ProjectDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
