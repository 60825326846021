export function validateName(value) {
  let error;
  if (!value) {
    error = "Name is Required";
  } else if (!/^[a-z][A-Z\s]+$/i.test(value)) {
    error = "Enter a Valid Name It should contain only Aplhabets";
  } else if (value.length > 30) {
    error = "Name Should not more than 30";
  } else if (value.length < 3) {
    error = "Name Should not less than 3 Characters";
  }
  return error;
}

export function validateEmail(value) {
  let error;
  if (!value) {
    error = "Email is Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
}

export function validateMobileNumber(value) {
  let error;
  if (!value) {
    error = "Mobile Number is Required";
  } else if (value.length > 10) {
    error = "Mobile Number Should not more than 10";
  } else if (value.length < 10) {
    error = "Mobile Number Should not less than 10 ";
  } else if (
    !/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i.test(value)
  ) {
    error = "Invalid Mobile Number...Enter Numeric";
  }
  return error;
}

export function validtaeOTP(value) {
  let error;
  if (!value) {
    error = "OTP is Required";
  } else if (value.length > 4) {
    error = "OTP Should not more than 4";
  } else if (value.length < 4) {
    error = "OTP Should Should not less than 4";
  } else if (!/^\d{4}$/i.test(value)) {
    error = "Invalid OTP...Enter Numeric";
  }
  return error;
}
