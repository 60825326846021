import React from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Header/Navbar";
import Cards from "./Cards";

export default function Listing() {
  return (
    <div>
      <section>
        <Navbar />
      </section>
      <div className="bul_sliding_comp">
        <Cards />
      </div>
      <section className="bul_footer">
        <Footer />
        <div className="bul_boxCopyright">
          <div className="container">
            <p className="mb-0">
              © {new Date().getFullYear()} www.cendrol.com All rights reserved
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
