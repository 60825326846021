import React, { useState, useCallback, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ShareIcon from "@mui/icons-material/Share";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import Modal from "@mui/material/Modal";
import AddIcon from "@mui/icons-material/Add";
import thankyou from "../../Assets/Svg/Thankyou.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Buildupareagrid from "../../Assets/png/Buildupareagrid.png";
import nooffloors from "../../Assets/png/NumberofFloorsicon.jpg";
import proval from "../../Assets/png/ProjectValueicone.jpg";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Bathroomicone from "../../Assets/jpg/Bathroomicone.jpg";
import durationicon from "../../Assets/png/Durationicon.jpg";
import plotsizeicon from "../../Assets/png/Plotsizeicone.jpg";
import OtpInput from "react-otp-input";
import { WhatsappShareButton } from "react-share";
import GoogleMaps from "./GoogleMaps";
import CloseIcon from "@mui/icons-material/Close";
import Fab from "@mui/material/Fab";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import facingicon from "../../Assets/png/Facingicon.jpg";
import { Carousel } from "react-responsive-carousel";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  validateEmail,
  validateMobileNumber,
  validateName,
  validtaeOTP,
} from "./Validation";
export default function ProjectOverview({ ID }) {
  const [slideIndex, setSlideIndex] = useState(0);

  function updateSlide(index) {
    setSlideIndex(index);
  }

  const [isLoding, setIsLoding] = useState(false);
  const [readmore, setReadmore] = useState(false);
  const [BtnLoader, setBtnLoader] = useState(false);
  const [disfalse, setDisFalse] = useState(false);
  const otpInput = useRef();
  const [otp, setotp] = useState("");
  const [otperror, setotperror] = useState(false);
  const [focusotp, setfocusotp] = useState(true);
  const [number, setnumber] = useState("");
  const [otpsentsuccess, setSentOtpSuccess] = useState(false);
  const [formsubmit, setFormSubmit] = useState(false);
  const [projectdetailsvalues, setProjectDetailsValues] = useState([]);
  const [projectImages, setProjectImages] = useState([]);
  const [mapmarker, setMapMarker] = useState([]);
  const [LinkCopied, setLinkCopied] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openone, setOpenone] = React.useState(false);
  const handleOpenone = () => setOpenone(true);
  const handleCloseone = () => setOpenone(false);

  const IMAGE_URL = process.env.REACT_APP_IMG_URL;

  let Data =
    "Lorem ipsum dolor sit amet,nsectetur adipiscing elit.Dictumst attincidunt sed risus quis  vulputatetortor consectetur adipiscing elit.Dictumst attincidunt sed risus quis  vulputatetortor morbi sagitti  Dictumst at tincidunt sed risus quis vulpu tatetortor morbi sagittis.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Dictumst attincidunt sed risus quis  vulputatetortor consectetur adipiscing elit.Dictumst attincidunt sed risus quis  vulputatetortor morbi sagittis";

  useEffect(() => {
    // getProjectDetails();
  }, []);

  useEffect(() => {
    (async () => {
      window.scroll({
        top: 0,
      });
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ screen: "home" })
      );
      const query = new URLSearchParams(window.location.search);

      if (query.get("dev")) {
        // console.log("dev");
        localStorage.setItem("e", "dev");
      } else if (query.get("qa")) {
        localStorage.setItem("e", "qa");
        // console.log("qa");
      } else if (query.get("preprod")) {
        localStorage.setItem("e", "preprod");
        // console.log("preprod");
      } else {
        localStorage.setItem("e", "customer");
        console.log("production");
      }
      let data = localStorage.getItem("e");

      const env = query.get(`${data}`);

      localStorage.setItem("projectId", env);
      // console.log("env", env);

      function wait(timeout) {
        if (env) {
          localStorage.setItem("projectId", env);
        }
        // if (env) {
        //   localStorage.setItem("e", env);
        // }
        return new Promise((resolve) => {
          setTimeout(resolve, timeout);
        });
      }

      wait(100).then(() => getProjectDetails());
    })();
  }, []);

  // useEffect(async () => {
  //   // setloader(true);

  // }, []);

  const removePopup1 = () => {
    window.$("#thankyounew").modal("hide");
  };

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    // width: 30,
    // height: 30,
    cursor: "pointer",
  };

  const settings = {
    infiniteLoop: true,
    autoPlay: true,
    interval: 5000,
    centerMode: true,
    centerSlidePercentage: 75,
    showArrows: true,
    showStatus: false,
    showThumbs: false,
    showIndicators: false,
    onChange: (index) => {
      updateSlide(index);
    },
    swipeable: false,
    stopOnHover: true,
    swipeScrollTolerance: 2,
    selectedItem: parseInt(slideIndex),
  };

  const getProjectDetails = () => {
    axios
      .get(
        `${
          localStorage.getItem("e") === "customer"
            ? process.env.REACT_APP_BASE_URL
            : process.env.REACT_APP_BASE_URL_LOCAL_SERVER
        }${
          localStorage.getItem("e") === "customer"
            ? ""
            : localStorage.getItem("e")
        }/getCendrolProject?project_name=${localStorage.getItem("projectId")}`
      )
      .then((resultval) => {
        // console.log("RESULT VALUE OF ONLY", resultval.data.result[0].projects);
        setProjectDetailsValues(resultval.data.result[0].projects);
        setMapMarker(resultval.data.result);
        // console.log("INSDE", resultval.data.result[0]);
        setProjectImages(resultval.data.result[0].projects.project_images);
      });
  };

  // Handle Change for OTP
  const handleChangeOTP = (e) => {
    setotp(e);
    // console.log("Value", otp);
    if (e.length >= 4) {
      verifyOTP(e);
    }
  };

  // Handle Change for Number & To send Otp
  const handleChangeNumber1 = (e) => {
    setnumber(e.target.value);
  };

  // Handle Change for Number
  const handleChangeNumber = (e) => {
    setnumber(e.target.value);
  };

  // function to send otp
  const verifyFunc = () => {
    setBtnLoader(true);
    let mobile = number;
    var regex = /^[0-9]{10}$/;
    if (mobile.length === 10) {
      if (mobile.match(regex)) {
        const data = { mobile: mobile };
        axios
          .post(
            `${
              process.env.REACT_APP_BASE_URL + localStorage.getItem("e")
            }/sendotp`,
            data
          )
          .then((res) => {
            let type = res.data.msg.type;
            if (type === "success") {
              setSentOtpSuccess(true);
              setBtnLoader(false);
              document.getElementById("otp_sent").style.display = "block";
              // document.getElementById("resend_otp_link").style.pointerEvents =
              //   "auto";
            } else {
              alert("internal error");
            }
          });
      } else {
        alert("Please Enter a valid Mobile Number");
      }
    }
  };

  // function for to chek user has already sent details switching modals
  const handleUserPopUp = () => {
    var user = localStorage.getItem("Verify");
    // alert(user);
    if (user === "" || user === null) {
      console.log("IF");
      window.$("#exampleModal").modal("show");
    } else {
      console.log("Else");
      window.$("#thankyounew").modal("show");
    }
  };

  const postDatatoLeads = () => {
    const data = {
      mobile: number,
    };
    axios
      .post(
        `${
          process.env.REACT_APP_BASE_URL + localStorage.getItem("e")
        }/projectLeads`,
        data
      )
      .then((response) => {
        // console.log("daata added", response);
      });
  };

  // to verify otp function
  const verifyOTP = (otpverify) => {
    // console.log("TO VERIFY FUNCTION ENTERED INSIDE", otpverify);
    const data = {
      mobile: number,
      otp: otpverify,
    };

    axios
      .post(
        `${
          localStorage.getItem("e") === "customer"
            ? process.env.REACT_APP_BASE_URL
            : process.env.REACT_APP_BASE_URL_LOCAL_SERVER
        }${localStorage.getItem("e")}/verifyOtp?platform=web`,
        data
      )
      .then((res) => {
        if (res.data.msg.type === "success") {
          document.getElementById("otp_re_sent").style.display = "none";
          postDatatoLeads();
          document.getElementById("otp_verified").style.display = "block";

          setTimeout(() => {
            setFormSubmit(true);
          }, "2000");
          localStorage.setItem("Verify", "True");
          // setinvalidMpin(false);
          // setotperror(false);
          // history.push("/ResetMpinscreen");
        } else {
          // setinvalidMpin(true);
          // setotperror(true);
          document.getElementById("otp_mismatch").style.display = "block";
          // document.getElementById("otp_sent").style.display = "none";
          // document.getElementById("otp_verified").style.display = "none";
        }
      });
  };

  // Reset otp
  const resetOTP = () => {
    setotp("");
  };

  // Verifyotp
  const verifyOtp = (otpverify) => {
    let sendNumber = number;

    const data = {
      mobile: sendNumber,
      otp: otpverify,
    };

    axios
      .post(
        `${
          localStorage.getItem("e") === "customer"
            ? process.env.REACT_APP_BASE_URL
            : process.env.REACT_APP_BASE_URL_LOCAL_SERVER
        }${localStorage.getItem("e")}/verifyOtp`,
        data
      )
      .then((res) => {
        let type = res.data.msg.type;
        if (type === "success") {
          document.getElementById("otp_verified1").style.display = "block";
          document.getElementById("otp_mismatch1").style.display = "none";
          // document.getElementById("signup").style.pointerEvents = "auto";
          document.getElementById("signup").style.display = "block";
          localStorage.setItem("Verify", "True");
        } else if (type === "error") {
          resetOTP();
          document.getElementById("otp_mismatch1").style.display = "block";
          document.getElementById("otp_verified1").style.display = "none";
        }
      });
  };

  // To Resend OTP function
  const resendOTP = () => {
    let sendNumber = number;
    const data = {
      mobile: sendNumber,
    };

    axios
      .post(
        `${
          localStorage.getItem("e") === "customer"
            ? process.env.REACT_APP_BASE_URL
            : process.env.REACT_APP_BASE_URL_LOCAL_SERVER
        }${localStorage.getItem("e")}/resendOtp`,
        data
      )
      .then((res) => {
        let type = res.data.msg.type;
        if (type === "success") {
          document.getElementById("otp_sent").style.display = "none";
          document.getElementById("otp_re_sent").style.display = "block";
          document.getElementById("otp_mismatch").style.display = "none";
          // document.getElementById("otp_sent_popup").style.display = "none";
          // document.getElementById("otp_re_sent_popup").style.display = "block";
        } else if (type === "error") {
          alert("OTP limit reached please try again");
        } else {
          alert("Mainteance Error!!!");
        }
      });
  };

  const GoogleMapaComponent = useCallback(
    ({ latt, long }) => {
      return <GoogleMaps isLoding={isLoding} latt={latt} long={long} />;
    },
    [mapmarker]
  );

  // TO post data to api
  const SaveUserData1 = (data, resetForm) => {
    const adduser = {
      mobile: number,
    };
    // console.log("user", adduser);

    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        `${
          localStorage.getItem("e") === "customer"
            ? process.env.REACT_APP_BASE_URL
            : process.env.REACT_APP_BASE_URL_LOCAL_SERVER
        }${localStorage.getItem("e")}/projectLeads`,
        adduser,
        {
          headers,
        }
      )
      .then((res) => {
        let Status = res.data.status;
        if (Status === "success") {
          // gtag_report_conversion();
          window.$("#exampleModal").modal("hide");

          resetForm({ data: "" });
          localStorage.setItem("Verify", "True");
          localStorage.setItem("FormVerified", "True");
          setnumber("");
          setotp("");
        } else if (Status === "failed") {
          // toast.error("Details are Already Exists", {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: true,
          //   closeOnClick: true,SaveUserData1
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          alert("Details Already Exists");
          resetForm({ data: "" });
          setnumber("");
          setotp("");
        }
      });
  };

  // Handle Change for OTP
  const handleChangeOTP1 = (e) => {
    setotp(e.target.value);
    let otpverify = e.target.value;
    if (otpverify.length === 4) {
      verifyOtp(otpverify);
    }
  };

  const verify_btn = () => {
    var regex = /^[0-9]{10}$/;
    if (number.length === 10) {
      if (number.match(regex)) {
        const data = { mobile: number };
        axios
          .post(
            `${
              localStorage.getItem("e") === "customer"
                ? process.env.REACT_APP_BASE_URL
                : process.env.REACT_APP_BASE_URL_LOCAL_SERVER
            }${localStorage.getItem("e")}/sendotp`,
            data
          )
          .then((res) => {
            let type = res.data.msg.type;
            if (type === "success") {
              document.getElementById("verify_btn").style.display = "none";
              document.getElementById("otp_sent1").style.display = "block";
              document.getElementById("user_otp").style.display = "block";
              document.getElementById("resendlink").style.pointerEvents =
                "auto";
            } else {
              alert("internal error");
            }
          });
      }
    }
  };

  // TO Resend OTP
  const resendOTP1 = () => {
    let sendNumber = number;

    const data = {
      mobile: sendNumber,
    };

    axios
      .post(
        `${
          localStorage.getItem("e") === "customer"
            ? process.env.REACT_APP_BASE_URL
            : process.env.REACT_APP_BASE_URL_LOCAL_SERVER
        }${localStorage.getItem("e")}/resendOtp`,
        data
      )
      .then((res) => {
        let type = res.data.msg.type;
        if (type === "success") {
          document.getElementById("otp_sent1").style.display = "none";
          document.getElementById("otp_re_sent").style.display = "block";
        } else if (type === "error") {
          alert("OTP limit reached please try again");
        } else {
          alert("Mainteance Error!!!");
        }
      });
  };

  return (
    <div>
      <div className="main_div_cards_detailspage">
        <div className="navigation_section">
          <Link to={`/`}>
            <ArrowBackIosIcon fontSize="small" type="left" />
            <span style={{ fontSize: "16px", fontFamily: "mm" }}>Back</span>
          </Link>
        </div>
        <div className="project_details_section">
          <div className="d-flex justify-content-between align-content-center projec_header_section">
            <div>
              <div className="d-flex align-items-center">
                <p className="mb-0 projectCustomer">
                  Customer : {projectdetailsvalues?.project_name}
                </p>
                {/* <span className="project_tag_details ms-2">
                  {projectdetailsvalues?.category}
                </span> */}
              </div>
              <p className="locationProject">
                {projectdetailsvalues?.plot_location}
              </p>
            </div>
            <div className="sharables_btn" style={{ gap: "10px" }}>
              <div className="d-flex">
                <div className="shareble_btn" onClick={handleOpenone}>
                  <ShareIcon fontSize="small" style={{ color: "#F7B314" }} />
                  <p className="mb-0 ms-2 projectShare">Share</p>
                </div>
              </div>
              <div className="shareBTN" onClick={handleUserPopUp}>
                <p className="mb-0 ms-2 projectShare">Get Your Free Estimate</p>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="parentGrid" onClick={handleOpen}>
              <div
                className="parentGrid1"
                style={{
                  background: `url("${
                    localStorage.getItem("e") === "customer"
                      ? "https://d2aknbw0t7002k.cloudfront.net"
                      : "https://d48j7fd3ucy92.cloudfront.net"
                  }/${projectImages[0]}")`,
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div
                className="parentGrid2"
                style={{
                  background: `url("${
                    localStorage.getItem("e") === "customer"
                      ? "https://d2aknbw0t7002k.cloudfront.net"
                      : "https://d48j7fd3ucy92.cloudfront.net"
                  }/${projectImages[1]}")`,
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div
                className="parentGrid3"
                style={{
                  background: `url("${
                    localStorage.getItem("e") === "customer"
                      ? "https://d2aknbw0t7002k.cloudfront.net"
                      : "https://d48j7fd3ucy92.cloudfront.net"
                  }/${projectImages[2]}")`,
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
              <div
                className="parentGrid4"
                style={{
                  background: `url("${
                    localStorage.getItem("e") === "customer"
                      ? "https://d2aknbw0t7002k.cloudfront.net"
                      : "https://d48j7fd3ucy92.cloudfront.net"
                  }/${projectImages[3]}")`,
                  backgroundSize: "100%",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {/* <div
                  className="CoverImg"
                  style={{ cursor: "pointer" }}
                  onClick={handleOpen}
                >
                  <AddIcon />
                  <p className="moreImg">{projectImages.length - 4} More</p>
                </div> */}
              </div>
            </div>
          </div>
          <div className="Overviewproject mt-4 ">
            <div className="Overviewtext">Overview Of Project</div>
            <hr className="m-0" />
            <div>
              <div className="row overview_icons">
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img src={Buildupareagrid} alt="icon" width={45} />
                    </div>
                    <div className="ms-2">
                      <p className="m-0 Builtareaproject ">Built Up Area</p>
                      <p className="m-0 Builtareaprojectsub">
                        {projectdetailsvalues?.builtup_area
                          ? projectdetailsvalues?.builtup_area
                          : "-"}{" "}
                        Sq.ft.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img src={plotsizeicon} alt="icon" width={45} />
                    </div>
                    <div className="ms-2">
                      <p className="m-0 Builtareaproject">Plot Size</p>
                      <p className="m-0 Builtareaprojectsub">
                        {" "}
                        {projectdetailsvalues?.plot_dimension
                          ? projectdetailsvalues?.plot_dimension
                          : "-"}{" "}
                        Sq.ft.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img src={plotsizeicon} alt="icon" width={45} />
                    </div>
                    <div className="ms-2">
                      <p className="m-0 Builtareaproject">Size</p>
                      <p className="m-0 Builtareaprojectsub">
                        {projectdetailsvalues?.specs
                          ? projectdetailsvalues?.specs
                          : "-"}{" "}
                        BHK
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={Bathroomicone} alt="icon" width={45} />
                    </div>
                    <div className="ms-2">
                      <p className="m-0 Builtareaproject">Bathrooms</p>
                      <p className="m-0 Builtareaprojectsub">
                        {projectdetailsvalues?.no_of_bathrooms
                          ? projectdetailsvalues?.no_of_bathrooms
                          : "-"}{" "}
                        Bathrooms
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 mt-md-4 mt-lg-4">
                  <div className="d-flex align-items-center">
                    <div className="">
                      <img src={proval} alt="icon" width={45} />
                    </div>
                    <div className="ms-2">
                      <p className="m-0 Builtareaproject">Project Value</p>
                      <p className="m-0 Builtareaprojectsub">
                        {projectdetailsvalues?.project_value
                          ? projectdetailsvalues?.project_value
                          : "-"}{" "}
                        Lakhs
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 mt-md-4 mt-lg-4">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={facingicon} alt="icon" width={45} />
                    </div>
                    <div className="ms-2">
                      <p className="m-0 Builtareaproject">Facing</p>
                      <p className="m-0 Builtareaprojectsub">
                        {projectdetailsvalues?.facing
                          ? projectdetailsvalues?.facing
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 mt-md-4 mt-lg-4">
                  <div className="d-flex align-items-center">
                    <div>
                      <img src={nooffloors} alt="icon" width={45} />
                    </div>
                    <div className="ms-2">
                      <p className="m-0 Builtareaproject">No of Floors</p>
                      <p className="m-0 Builtareaprojectsub">
                        G +{" "}
                        {projectdetailsvalues?.no_of_floors
                          ? projectdetailsvalues?.no_of_floors
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-3 mt-md-4 mt-lg-4">
                  <div className="d-flex -align-items-center">
                    <div className="">
                      <img src={durationicon} alt="icon" width={45} />
                    </div>
                    <div className="ms-2">
                      <p className="m-0 Builtareaproject">Duration</p>
                      <p className="m-0 Builtareaprojectsub">
                        {projectdetailsvalues?.duration
                          ? projectdetailsvalues?.duration
                          : "-"}
                        {"  "}
                        months
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Overviewproject mt-4 ">
            <div className="Overviewtext">About Project</div>
            <hr className="m-0" />
            <div className="proj_descrip">
              <p className="about_project">
                {/* {projectdetailsvalues?.project_description} */}
                {projectdetailsvalues?.project_description?.slice(0, 200)}
                {!readmore ? ".." : ""}
                {readmore && (
                  <span>
                    {projectdetailsvalues?.project_description.slice(
                      200,
                      Infinity
                    )}
                  </span>
                )}
                {!readmore ? (
                  <span
                    className="readmore_txt"
                    onClick={() => setReadmore(true)}
                  >
                    {" "}
                    read more
                  </span>
                ) : (
                  <span
                    className="readmore_txt"
                    onClick={() => {
                      setReadmore(false);
                    }}
                  >
                    {" "}
                    <br />
                    view less
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className="mt-4 Overviewproject">
            <div className="d-flex justify-content-between">
              <div className="">
                <p className="m-0 Overviewtext">Project Location</p>
              </div>
              <div className=""></div>
            </div>
            <hr className="m-0" />
            <div
              className="Aboutproject mt-4 "
              style={{ padding: "0px 18px 17px 18px" }}
            >
              <GoogleMapaComponent
                latt={mapmarker[0]?.projects.latitude}
                long={mapmarker[0]?.projects.longitude}
              />
            </div>
          </div>
          {localStorage.getItem("Verify") === "True" ? (
            <div className="mt-4">
              <div className="thank_you_sec">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-8">
                    <div className="thankyou">
                      <p className="">
                        Thank you for
                        <br /> your interest in cendrol
                      </p>
                      <span className="">
                        One of our Sales Representatives will be in <br />{" "}
                        contact with you shortly.
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-5 col-lg-4">
                    <img src={thankyou} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="form_section mt-4">
                <div className="row">
                  <div className="col-12 col-md-8 planing_to_build">
                    <div style={{ padding: "10px" }}>
                      <p className="mb-2 form_heading_1">
                        Your dream home <br className="break" /> is just a few
                        clicks away!
                      </p>
                      <span className="form_heading_2">
                        Get complete details of our packages,
                        <br className="break" /> sample designs and more
                        directly to <br className="break" /> your inbox!
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="d-flex justify-content-center">
                      <div className="">
                        <p className="mob_no m-0">Mobile Number</p>
                        <div className="d-flex">
                          <div className="plus_91_div">+91</div>
                          <div className="sunscribe_div">
                            <input
                              type="tel"
                              className="subscribe_textbox"
                              placeholder="eg. 8328954XXX"
                              onKeyUp={(e) => handleChangeNumber(e)}
                              onWheel={(e) => e.target.blur()}
                              maxLength={10}
                              onKeyDown={(event) => {
                                if (!/[0-9Backspace]/.test(event.key)) {
                                  event.preventDefault();
                                }
                                ["e", "a", "p", "s", "c", "k"].includes(
                                  event.key
                                ) && event.preventDefault();
                              }}
                            />
                            <button
                              className="subscribe_verify_btn d-flex align-items-center"
                              onClick={verifyFunc}
                              disabled={number ? "true" : ""}
                            >
                              Verify
                              {BtnLoader === true ? (
                                <span
                                  className="spinner-border spinner-border-sm ms-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              ) : (
                                ""
                              )}
                            </button>
                          </div>
                        </div>
                        <span
                          id="otp_sent"
                          className="otp_sent"
                          style={{ display: "none" }}
                        >
                          OTP Sent
                        </span>
                        {/* {otpsentsuccess ? ( */}
                        <div className="mt-3">
                          <span className="mob_no text-light">Enter OTP</span>
                          <br />
                          <div className="w-100" style={{ width: "90%" }}>
                            <OtpInput
                              isInputNum={true}
                              value={otp}
                              className="OTP_input"
                              ref={otpInput}
                              shouldAutoFocus={focusotp && true}
                              onChange={handleChangeOTP}
                              separator={<span> </span>}
                              containerStyle={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              inputStyle={{
                                width: "4rem",
                                height: "3rem",
                                color: "#000",
                                marginTop: "14px",
                                fontSize: "1rem",
                                background: "#FAFAFA",
                                borderRadius: 2,
                                border: `1px solid ${
                                  otperror ? "red" : "rgba(0, 0, 0, 0.3)"
                                }`,
                              }}
                            />
                          </div>
                          <div className="mt-2">
                            <p
                              id="otp_verified"
                              className="otp_sent mt-2"
                              style={{
                                display: "none",
                                fontSize: "12px",
                                lineHeight: "12px",
                              }}
                            >
                              OTP verified
                            </p>
                          </div>
                          <div className="mt-2">
                            <p
                              id="otp_verified"
                              className="otp_sent mt-2"
                              style={{
                                display: "none",
                                fontSize: "12px",
                                lineHeight: "12px",
                              }}
                            >
                              OTP verified
                            </p>
                            <p
                              id="otp_re_sent"
                              className="otp_sent"
                              style={{
                                display: "none",
                                fontSize: "12px",
                                lineHeight: "12px",
                              }}
                            >
                              OTP Resent Successfully
                            </p>
                            <p
                              id="otp_sent"
                              className="otp_sent"
                              style={{ display: "none" }}
                            >
                              OTP Sent
                            </p>
                            <p
                              id="otp_mismatch"
                              className="otp_wrong"
                              style={{
                                display: "none",
                                fontSize: "12px",
                                lineHeight: "10px",
                              }}
                            >
                              Wrong OTP...ReEnter
                            </p>
                          </div>
                          <div className="pb-3">
                            <span className="form_OTP_not">
                              Didn’t Receive OTP
                              <span style={{ fontFamily: "none" }}>?</span>
                            </span>
                            <span
                              id="resendlink"
                              className="resend_otp"
                              onClick={resendOTP}
                            >
                              {" "}
                              Resend OTP
                            </span>
                          </div>
                        </div>
                        {/* // ) : (
                    //   ""
                    // )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* carousel popup */}
      <Modal
        open={open}
        // onClose={handleClose}
        className="relative"
        style={{ background: "rgba(14, 14, 14, 0.95)" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <div style={{ width: "70%", margin: "auto" }}>
            <div className="d-flex justify-content-between align-items-center mt-3">
              <div>
                <div className="d-flex align-items-center">
                  <p
                    className="mb-0 projectCustomer "
                    style={{ color: "#FFFFFF" }}
                  >
                    Customers : {projectdetailsvalues?.project_name}
                  </p>
                  <span className="TrendingTag ms-2">Trending</span>
                </div>
                <div className="d-flex  align-items-center">
                  <p className="outoffimg mb-0">
                    {slideIndex + 1}/{projectImages?.length}
                  </p>
                  <div
                    class="progress ms-2"
                    style={{ height: "5px", width: "40%" }}
                  >
                    <div
                      className="progress-barSlider"
                      role="progressbar"
                      style={{
                        width: `${
                          ((slideIndex + 1) / projectImages?.length) * 100
                        }%`,
                        background: "#F7B314",
                      }}
                      aria-valuenow="0"
                      aria-valuemin="0"
                      aria-valuemax={`${slideIndex}`}
                    ></div>
                  </div>
                </div>
              </div>
              <CloseIcon style={{ color: "#FFFFFF" }} onClick={handleClose} />
            </div>
          </div>
          <div className="caro_div">
            <Carousel
              {...settings}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <Fab
                    size="small"
                    className="cendrolproject_leftarrow"
                    onClick={onClickHandler}
                    title={label}
                    style={{ ...arrowStyles, left: 100 }}
                    // style={{ boxShadow: "none", zIndex: 0 }}
                    // className="cendrolproject_leftarrow"
                    aria-label="add"
                  >
                    <ArrowBackIcon fontSize="small" type="left" />
                  </Fab>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <Fab
                    size="small"
                    onClick={onClickHandler}
                    title={label}
                    style={{ ...arrowStyles, right: 100 }}
                    // style={{ boxShadow: "none", zIndex: 0 }}
                    className="cendrolproject_rightarrow"
                    aria-label="add"
                  >
                    <ArrowForwardIcon fontSize="small" type="right" />
                  </Fab>
                )
              }
            >
              {projectImages.map((val, i) => (
                <div className="m-2 carousel_img_div">
                  <img
                    width="100%"
                    className="caro_img"
                    src={`${
                      localStorage.getItem("e") === "customer"
                        ? "https://d2aknbw0t7002k.cloudfront.net"
                        : "https://d48j7fd3ucy92.cloudfront.net"
                    }/${val}`}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </>
      </Modal>

      {/* Share Project */}
      <Modal
        open={openone}
        className="relative d-flex justify-content-center align-items-center flex-column"
        style={{ background: "rgba(0, 0, 0, 0.9)" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="share_modal" style={{}}>
          <div className="d-flex justify-content-between align-item-center">
            <p className="Sharepop">Share Project</p>
            <CloseIcon
              style={{ cursor: "pointer" }}
              className="close_icon"
              onClick={() => handleCloseone()}
            />
          </div>

          <div className="linkcopy">
            <div
              className="d-flex align-items-center justify-content-start"
              style={{ width: "80%" }}
            >
              {/* <form style={{ width: "90%", visibility: "hidden" }}>
                <textarea
                  ref={textAreaRef}
                  value="Some text to copy"
                  style={{ width: "90%" }}
                />
              </form> */}

              <p className="mb-0 linkcopytext">
                {`https://www.projects.cendrol.com/details?${localStorage.getItem(
                  "e"
                )}=${localStorage.getItem("projectId")}`}
              </p>
            </div>
            {/* <div
              className="copyLink"
              
            >
            </div> */}
            <ContentCopyIcon
              fontSize="small"
              className="me-2"
              style={{ color: "#F7B314", cursor: "pointer" }}
              onClick={() => {
                toast.info(` link Copied !`, {
                  position: "bottom-center",
                  hideProgressBar: false,
                });
                navigator.clipboard.writeText(
                  `https://www.projects.cendrol.com/details?${localStorage.getItem(
                    "e"
                  )}=${localStorage.getItem("projectId")}`
                );
                setLinkCopied(true);
              }}
            />
          </div>
          {LinkCopied ? (
            <>
              <p className="link_copied">Link Copied</p>
            </>
          ) : (
            ""
          )}
          <p className="or">or</p>
          <div className="d-flex justify-content-center align-items-center">
            {/* <a
              style={{ color: "#fff" }}
              target="_blank"
              href={`https://wa.me?text=https://www.projects.cendrol.com/prodetails?_id=${ID}`}
            > */}
            <div className="btnSharelink">
              <WhatsappShareButton
                className="d-flex justify-content-center align-items-center"
                url={`https://www.projects.cendrol.com/details?${localStorage.getItem(
                  "e"
                )}=${localStorage.getItem("projectId")?.replace(/ /g, "%20")}`}
              >
                <WhatsAppIcon /> <p className="mb-0">Share via WhatsApp</p>
              </WhatsappShareButton>
            </div>
            {/* </a> */}
          </div>
        </div>
      </Modal>
      {/*  */}

      {/* Popup to verify otp with form */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal_content_whole">
            <div className="modal-body">
              <div>
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="bul_modal_head_txt">
                      Planning To Build Your
                      <br /> Dream Home In Banglore?
                    </div>
                    <div className="bul_modal_sub_txt pt-2">
                      Get complete package details directly to your inbox.
                    </div>
                  </div>
                  <div type="button" data-bs-dismiss="modal" aria-label="Close">
                    <CloseIcon style={{ fontSize: "29px" }} />
                  </div>
                </div>

                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    fullname: "",
                    email: "",
                    number: "",
                  }}
                  onSubmit={(values, { resetForm }) => {
                    SaveUserData1(values, resetForm);
                  }}
                >
                  {({ errors, touched }) => (
                    <div>
                      <Form>
                        <div className="pt-4">
                          <div className="pb-3">
                            <label
                              htmlFor="number"
                              className="bul_user_form_label"
                            >
                              Phone Number
                            </label>

                            <div className="d-flex">
                              <span className="bul_number_edit_91">+91</span>
                              <div className="d-flex w-100 p-0 align-items-center bul_user_form_fileds">
                                <Field
                                  type="tel"
                                  className="form-control bul_user_form_fileds"
                                  id="number"
                                  name="number"
                                  placeholder="eg. 70900XXXXX"
                                  autoComplete="off"
                                  validate={validateMobileNumber}
                                  onKeyUp={(e) => handleChangeNumber1(e)}
                                  onWheel={(e) => e.target.blur()}
                                  maxLength={10}
                                  onKeyDown={(event) => {
                                    if (!/[0-9Backspace]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                    ["e", "a", "p", "s", "c", "k"].includes(
                                      event.key
                                    ) && event.preventDefault();
                                  }}
                                />
                                <div style={{ paddingRight: "5px" }}>
                                  <button
                                    id="verify_btn"
                                    className="bul_btn_verify"
                                    onClick={verify_btn}
                                    type="submit"
                                  >
                                    Verify
                                  </button>

                                  <span
                                    id="otp_sent1"
                                    className="bul_otp_sent"
                                    style={{
                                      display: "none",
                                      width: "max-content",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    OTP Sent
                                  </span>
                                  <span
                                    id="otp_re_sent"
                                    className="bul_otp_sent"
                                    style={{
                                      display: "none",
                                      width: "max-content",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    OTP Resend Successfully
                                  </span>
                                </div>
                              </div>
                            </div>
                            {errors.number && touched.number && (
                              <span className="bul_errors">
                                {errors.number}
                              </span>
                            )}
                          </div>

                          <div id="user_otp" style={{ display: "none" }}>
                            <div className="pb-3">
                              <label
                                htmlFor="otp"
                                className="bul_user_form_label"
                              >
                                Enter Your OTP
                              </label>

                              <Field
                                type="tel"
                                name="otp"
                                className="form-control bul_user_form_fileds"
                                id="otp"
                                placeholder="Enter OTP sent to your mobile number"
                                autoComplete="off"
                                onKeyUp={(e) => handleChangeOTP1(e)}
                                onWheel={(e) => e.target.blur()}
                                maxLength={4}
                                // onKeyDown={(e) =>
                                //   ["e", "E", "+", "-", "."].includes(e.key) &&
                                //   e.preventDefault()
                                // }
                                onKeyDown={(event) => {
                                  if (!/[0-9Backspace]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                  ["e", "a", "p", "s", "c", "k"].includes(
                                    event.key
                                  ) && event.preventDefault();
                                }}
                                validate={validtaeOTP}
                              />
                              {errors.otp && touched.otp && (
                                <span className="bul_errors">{errors.otp}</span>
                              )}

                              <span
                                id="otp_verified1"
                                className="bul_otp_sent"
                                style={{ display: "none", textAlign: "left" }}
                              >
                                OTP verified
                              </span>
                              <span
                                id="otp_mismatch1"
                                className="bul_otp_worong"
                                style={{ display: "none", textAlign: "left" }}
                              >
                                Wrong OTP...ReEnter
                              </span>
                            </div>

                            <div className="pb-3">
                              <span className="bul_form_OTP_not">
                                Don’t Received OTP?{" "}
                              </span>
                              <span
                                id="resendlink"
                                className="bul_resend_otp"
                                onClick={() => resendOTP1(number)}
                              >
                                Resend OTP
                              </span>
                            </div>
                          </div>

                          <div id="btn_signup">
                            <button
                              id="signup"
                              type="submit"
                              className="bul_popup_form_btn"
                            >
                              Get Your Free Estimate
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Thank you */}
      <div
        className="modal fade"
        id="thankyounew"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "9999" }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          // style={{ width: "30%" }}
        >
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-center bul_thankyou_heading">
                Thank you for
                <br /> your interest in Cendrol.
              </div>
              <div className="d-flex justify-content-center">
                <div className="bul_thank_line"></div>
              </div>
              <p className="text-center bul_thank_subheading">
                One of our Sales Representatives will be in <br />
                contact with you shortly.
              </p>
              <div>
                <button
                  className="w-100 bul_keep_browsing"
                  onClick={removePopup1}
                >
                  Keep Browsing
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
