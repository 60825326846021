import React from "react";
import "./styles.css";
import add_partner from "../../Assets/Svg/add_partner.svg";
import Cendrol from "../../Assets/Svg/logo.svg";
import mail_icon from "../../Assets/Svg/email.svg";
import whatsappicon from "../../Assets/Svg/whatsapp.svg";
export default function Navbar() {
  return (
    <div>
      <div className="row justify-content-center" style={{ display: "block" }}>
        <div className="col-11 col-md-11">
          <div>
            <nav className="navbar navbar-expand-lg fixed-top navbar-light bul_build_nav">
              <div className="container-fluid bul_navbar_container">
                <a href="/">
                  <img src={Cendrol} className="bul_cendrol_log" alt="" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarTogglerDemo02"
                  aria-controls="navbarTogglerDemo02"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse bul_land_nav_toggle"
                  id="navbarTogglerDemo02"
                >
                  <div className="navbar-nav ms-auto">
                    <div className="bul_navbar_li_div align-self-center">
                      <ul className="navbar-nav bul_land_nav_ul">
                        <li
                          className="nav-item align-self-center px-3"
                          style={{ listStyleImage: "unset" }}
                        >
                          <a
                            className="align-items-center"
                            href="mailto:sales@cendrol.com"
                            title="sales@cendrol.com"
                          >
                            {/* <DraftsIcon className="bul_nav_bul_mail_icons" /> */}
                            <img src={mail_icon} />
                            sales@cendrol.com
                          </a>
                        </li>
                        <li
                          className="nav-item align-self-center px-3"
                          style={{ listStyleImage: "unset" }}
                        >
                          <a
                            className="align-items-center"
                            href="https://wa.me/919741977477?text=I%27m%20interested%20in%20Cendrol%20Construction"
                            target="_blank"
                            title="9741977477"
                          >
                            <img src={whatsappicon} />
                            +91 97419 77477
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* <a
                      href="https://partner.cendrol.com"
                      target="_blank"
                      className="side_menu_dashboardbtn_unactive"
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        className="d-flex bul_cal_btn align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <img src={add_partner} alt="" />
                        Become a Partner
                      </button>
                    </a> */}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
