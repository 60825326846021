import React, { useEffect } from "react";
import useMoveMarker from "../ReusableComponents/useMoveMarker";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

const TravellingMarker = ({ position, ...rest }) => {
  let [coordinates, setDestination] = useMoveMarker([
    position.lat,
    position.lng,
  ]);

  useEffect(() => {
    setDestination([position.lat, position.lng]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);
  return (
    <Marker
      position={{
        lat: coordinates[0],
        lng: coordinates[1],
      }}
      // {...rest}
    />
  );
};

export default TravellingMarker;
