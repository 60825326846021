import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Header/Navbar";
import ProjectOverview from "./ProjectOverview";
import { useParams } from "react-router";
export default function ProjectDetails() {
  const query = new URLSearchParams(window.location.search);
  if (query === "dev") {
    let url = "dev";
    var _id = query.get(`${url}`);
  }

  return (
    <div>
      <section>
        <Navbar />
      </section>
      <div className="bul_sliding_comp">
        <ProjectOverview ID={_id} />
      </div>
      <section className="bul_footer">
        <Footer />
        <div className="bul_boxCopyright">
          <div className="container">
            <p className="mb-0">
              © {new Date().getFullYear()} www.cendrol.com All rights reserved
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
