import React, { useCallback, useEffect, useState } from "react";

// import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import TravellingMarker from "../ReusableComponents/TravellingMarker";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
const containerStyle = {
  width: "400px",
  height: "400px",
};

const GoogleMaps = ({ latt, long, isLoding }) => {
  // console.log("latt", latt, "long", long);
  let center = {
    lat: Number(latt),
    lng: Number(long),
  };

  let [currentCoordinates, setCurrentCoordinates] = useState([
    Number(latt),
    Number(long),
  ]);

  return (
    <div>
      <LoadScript googleMapsApiKey="AIzaSyC4M5l3HV3DjWVYmk76oeusysnBKpM7TSM">
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "400px" }}
          center={center}
          zoom={20}
          // position={(`${12.906129}`, `${77.590438}`)}
        >
          <TravellingMarker
            position={{
              lat: currentCoordinates[0],
              lng: currentCoordinates[1],
            }}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default GoogleMaps;
